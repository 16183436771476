import React from 'react'
import four from '../images/four_oh_four.png'
import { Button, InternalLinkButtonWrapper, Column, Row } from '../theme/components'
import { Seo } from '../components/Seo'

const NotFoundPage = () => (
  <div style={{ height: '100vh' }}>
    <Seo
      metaDescription={"Page not Found"}
      title={"Page not Found"}
      slug={'/404'}
    />
    <Row align="center" justify="center" style={{ height: "100%" }}>
      <Column align="center" justify="center">
        <h4>Oops, this page doesn't exist</h4>
        <img
          style={{ maxHeight: '350px' }}
          src={four}
          alt="404 - Not Found - DeReader"
        />
        <div className="uk-flex uk-flex-center">
          <InternalLinkButtonWrapper to="/">
            <Button style={{ width: '350px' }} color="primary">
              Back to Land
          </Button>
          </InternalLinkButtonWrapper>
        </div>
      </Column>
    </Row>
  </div>
)

export default NotFoundPage
